import type { IBackgroundEffectOptions } from './JitsiStreamBackgroundEffect';
import JitsiStreamBackgroundEffect from './JitsiStreamBackgroundEffect';
import { loadScript, timeout } from './utils';
import { ToastMessageMeet } from '~/globals/meet';
import { simd } from 'wasm-feature-detect';

const models = {
  modelLandscape: '/libs/jitsi/virtual-background/vendor/models/selfie_segmentation_landscape.tflite'
};

let modelBuffer: ArrayBuffer;
let tflite: any;
let isWasmDisabled = false;

const segmentationDimensions = {
  modelLandscape: {
    height: 144,
    width: 256
  }
};

export async function createVirtualBackgroundEffect(
  virtualBackground: IBackgroundEffectOptions['virtualBackground'],
  toast?: ReturnType<typeof useToast>
) {
  if (!MediaStreamTrack.prototype.getSettings && !MediaStreamTrack.prototype.getConstraints) {
    throw new Error('JitsiStreamBackgroundEffect not supported!');
  }

  if (isWasmDisabled) {
    toast?.error(ToastMessageMeet.backgroundEffectError);
    return;
  }

  if (!tflite) {
    try {
      const tfliteTimeout = 10000;
      if (await simd()) {
        await loadScript('/libs/jitsi/virtual-background/vendor/tflite/tflite-simd.js');
        // @ts-expect-error
        tflite = await timeout(tfliteTimeout, window.createTFLiteSIMDModule());
      } else {
        await loadScript('/libs/jitsi/virtual-background/vendor/tflite/tflite.js');
        // @ts-expect-error
        tflite = await timeout(tfliteTimeout, window.createTFLiteModule());
      }
    } catch (err: any) {
      if (err?.message === '408') {
        toast?.error(ToastMessageMeet.backgroundEffectError);
        return;
      }
      isWasmDisabled = true;
      console.error('Looks like WebAssembly is disabled or not supported on this browser', err);
      toast?.error(ToastMessageMeet.webAssemblyWarningDescription);

      return;
    }
  }

  if (!modelBuffer) {
    const modelResponse = await fetch(models.modelLandscape);
    if (!modelResponse.ok) {
      throw new Error('Failed to download tflite model!');
    }
    modelBuffer = await modelResponse.arrayBuffer();
    tflite.HEAPU8.set(new Uint8Array(modelBuffer), tflite._getModelBufferMemoryOffset());
    tflite._loadModel(modelBuffer.byteLength);
  }

  const options = {
    ...segmentationDimensions.modelLandscape,
    virtualBackground
  };

  return new JitsiStreamBackgroundEffect(tflite, options);
}

import payload_plugin_Z0cFW4pZ8F from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@3.0.1_typescript@5.7.3_vue@3.5.13_typescript@5.7.3__/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_XTe2MqHXlz from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.4_db0@0.2.4_eslint@9.20.1_jiti@2.4.2__ior_hkmuzr3uaonrkne62pqtren7nq/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_tOYCrreNbt from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.4_db0@0.2.4_eslint@9.20.1_jiti@2.4.2__ior_hkmuzr3uaonrkne62pqtren7nq/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_cIarZqqkr3 from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.4_db0@0.2.4_eslint@9.20.1_jiti@2.4.2__ior_hkmuzr3uaonrkne62pqtren7nq/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_uJX16H4GUF from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/nuxt-site-config@3.0.6_magicast@0.3.5_vite@6.1.0_@types+node@22.13.4_jiti@2.4.2_sass@1.85.0_t_uwsirswpr7reaxknbc57dyrii4/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_CjaISW01qU from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.4_db0@0.2.4_eslint@9.20.1_jiti@2.4.2__ior_hkmuzr3uaonrkne62pqtren7nq/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_sGXnSxkOnB from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.4_db0@0.2.4_eslint@9.20.1_jiti@2.4.2__ior_hkmuzr3uaonrkne62pqtren7nq/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_S1FLQ8aJcj from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.4_db0@0.2.4_eslint@9.20.1_jiti@2.4.2__ior_hkmuzr3uaonrkne62pqtren7nq/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_wuQFuUZrfv from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.4_db0@0.2.4_eslint@9.20.1_jiti@2.4.2__ior_hkmuzr3uaonrkne62pqtren7nq/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_4bUGhTHvwx from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@3.0.1_typescript@5.7.3_vue@3.5.13_typescript@5.7.3__/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/dating-frontend/dating-frontend/.nuxt/components.plugin.mjs";
import prefetch_client_Wp4InaO83K from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.4_db0@0.2.4_eslint@9.20.1_jiti@2.4.2__ior_hkmuzr3uaonrkne62pqtren7nq/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_wy0B721ODc from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_client_fxr5QpR3De from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import components_client_BnPdEbyOq4 from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/nuxt-swiper@2.0.0_magicast@0.3.5/node_modules/nuxt-swiper/dist/runtime/plugins/components.client.js";
import plugin_client_YWJCB0VwLn from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/@samk-dev+nuxt-vcalendar@1.0.4_magicast@0.3.5_vue@3.5.13_typescript@5.7.3_/node_modules/@samk-dev/nuxt-vcalendar/dist/runtime/plugin.client.js";
import plugin_fvN6UhkcOb from "/home/runner/work/dating-frontend/dating-frontend/src/modules/nuxt-auth-sanctum/runtime/plugin.ts";
import http_plugin_nuxt_AkA5qLFcMG from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/@nuxt-alt+http@1.7.10_magicast@0.3.5_nuxi@3.21.1_sass@1.85.0_typescript@5.7.3_vue-tsc@2.2.2_typescript@5.7.3_/node_modules/@nuxt-alt/http/dist/runtime/http-plugin.nuxt.mjs";
import switch_locale_path_ssr_fOmpe8yEk7 from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/@nuxtjs+i18n@9.2.0_@vue+compiler-dom@3.5.13_eslint@9.20.1_jiti@2.4.2__magicast@0.3.5_rollup@4_uj4c5qssomvtmecucretx65aha/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect_QoAsYqiF3G from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/@nuxtjs+i18n@9.2.0_@vue+compiler-dom@3.5.13_eslint@9.20.1_jiti@2.4.2__magicast@0.3.5_rollup@4_uj4c5qssomvtmecucretx65aha/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_6e43xs619l from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/@nuxtjs+i18n@9.2.0_@vue+compiler-dom@3.5.13_eslint@9.20.1_jiti@2.4.2__magicast@0.3.5_rollup@4_uj4c5qssomvtmecucretx65aha/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_9UdcOPolM6 from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/pinia-plugin-persistedstate@4.2.0_@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@3.0.1_typescript@5._i2zp2tc3yybrwf6m3monumh6mm/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import plugin_client_j4jpWgJhim from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/nuxt-viewport@2.2.0_magicast@0.3.5_vue@3.5.13_typescript@5.7.3_/node_modules/nuxt-viewport/dist/runtime/plugin.client.js";
import plugin_RYhFRgzRQ8 from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/dayjs-nuxt@2.1.11_magicast@0.3.5/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import nuxt_plugin_iME3KC628D from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/nuxt-delay-hydration@1.3.8_magicast@0.3.5/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/home/runner/work/dating-frontend/dating-frontend/.nuxt/pwa-icons-plugin.ts";
import pwa_client_j9jSunzoPX from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/@vite-pwa+nuxt@0.10.6_magicast@0.3.5_vite@6.1.0_@types+node@22.13.4_jiti@2.4.2_sass@1.85.0_te_6j4f5cyfag5jhbul3ynnjiziri/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import siteConfig_FzB8SNm3vh from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/nuxt-seo-utils@6.0.8_magicast@0.3.5_rollup@4.34.7_vite@6.1.0_@types+node@22.13.4_jiti@2.4.2_s_msab62eswxiaoormzjju7wa46a/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/siteConfig.js";
import inferSeoMetaPlugin_3LSQlHkXwC from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/nuxt-seo-utils@6.0.8_magicast@0.3.5_rollup@4.34.7_vite@6.1.0_@types+node@22.13.4_jiti@2.4.2_s_msab62eswxiaoormzjju7wa46a/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/inferSeoMetaPlugin.js";
import titles_Cn1TvBNsSG from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/nuxt-seo-utils@6.0.8_magicast@0.3.5_rollup@4.34.7_vite@6.1.0_@types+node@22.13.4_jiti@2.4.2_s_msab62eswxiaoormzjju7wa46a/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/titles.js";
import defaultsWaitI18n_pGHzrDP83r from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/nuxt-seo-utils@6.0.8_magicast@0.3.5_rollup@4.34.7_vite@6.1.0_@types+node@22.13.4_jiti@2.4.2_s_msab62eswxiaoormzjju7wa46a/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/defaultsWaitI18n.js";
import api_routes_Q0W49V3wqt from "/home/runner/work/dating-frontend/dating-frontend/src/plugins/api-routes.ts";
import custom_pwa_register_client_fOvh2D2KfW from "/home/runner/work/dating-frontend/dating-frontend/src/plugins/custom-pwa-register.client.ts";
import dompurify_R8xb7bJPqP from "/home/runner/work/dating-frontend/dating-frontend/src/plugins/dompurify.ts";
import floating_1Jsyq3dLZq from "/home/runner/work/dating-frontend/dating-frontend/src/plugins/floating.ts";
import handle_app_color_mn7Bwa8Xck from "/home/runner/work/dating-frontend/dating-frontend/src/plugins/handle-app-color.ts";
import modal_saNsw8xGBA from "/home/runner/work/dating-frontend/dating-frontend/src/plugins/modal.ts";
import pusher_js_client_2TZYA6fBVP from "/home/runner/work/dating-frontend/dating-frontend/src/plugins/pusher-js.client.ts";
import select_fkZYP3esEZ from "/home/runner/work/dating-frontend/dating-frontend/src/plugins/select.ts";
import toast_notification_ycBNrvS44W from "/home/runner/work/dating-frontend/dating-frontend/src/plugins/toast-notification.ts";
import ssg_detect_bma1Qn1ivy from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/@nuxtjs+i18n@9.2.0_@vue+compiler-dom@3.5.13_eslint@9.20.1_jiti@2.4.2__magicast@0.3.5_rollup@4_uj4c5qssomvtmecucretx65aha/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  payload_plugin_Z0cFW4pZ8F,
  revive_payload_client_XTe2MqHXlz,
  unhead_tOYCrreNbt,
  router_cIarZqqkr3,
  _0_siteConfig_uJX16H4GUF,
  payload_client_CjaISW01qU,
  navigation_repaint_client_sGXnSxkOnB,
  check_outdated_build_client_S1FLQ8aJcj,
  chunk_reload_client_wuQFuUZrfv,
  plugin_vue3_4bUGhTHvwx,
  components_plugin_KR1HBZs4kY,
  prefetch_client_Wp4InaO83K,
  plugin_wy0B721ODc,
  plugin_client_fxr5QpR3De,
  components_client_BnPdEbyOq4,
  plugin_client_YWJCB0VwLn,
  plugin_fvN6UhkcOb,
  http_plugin_nuxt_AkA5qLFcMG,
  switch_locale_path_ssr_fOmpe8yEk7,
  route_locale_detect_QoAsYqiF3G,
  i18n_6e43xs619l,
  plugin_9UdcOPolM6,
  plugin_client_j4jpWgJhim,
  plugin_RYhFRgzRQ8,
  nuxt_plugin_iME3KC628D,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_j9jSunzoPX,
  siteConfig_FzB8SNm3vh,
  inferSeoMetaPlugin_3LSQlHkXwC,
  titles_Cn1TvBNsSG,
  defaultsWaitI18n_pGHzrDP83r,
  api_routes_Q0W49V3wqt,
  custom_pwa_register_client_fOvh2D2KfW,
  dompurify_R8xb7bJPqP,
  floating_1Jsyq3dLZq,
  handle_app_color_mn7Bwa8Xck,
  modal_saNsw8xGBA,
  pusher_js_client_2TZYA6fBVP,
  select_fkZYP3esEZ,
  toast_notification_ycBNrvS44W,
  ssg_detect_bma1Qn1ivy
]
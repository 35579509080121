import routerOptions0 from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.4_db0@0.2.4_eslint@9.20.1_jiti@2.4.2__ior_hkmuzr3uaonrkne62pqtren7nq/node_modules/nuxt/dist/pages/runtime/router.options.js";
const configRouterOptions = {
  strict: true,
  hashMode: false,
  scrollBehaviorType: "auto"
}
export const hashMode = false
export default {
...configRouterOptions,
...routerOptions0,
}